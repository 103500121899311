import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, Checkbox, Paginate, Tooltip,
} from 'sw-ui';

import { COMMENTS, DOCUMENT_FLAG } from '../../../../bi/constants/account';
import COMPONENTS from '../../../../bi/constants/components';

import HTTPCODES from '../../../../constants/http';
import ITEMSONPAGE from '../../../../constants/pagination';
import { FORMATDATE } from '../../../../constants/time';
import { TRIPBUTTONS } from '../../../../bi/constants/trips';

import { useServices } from 'v2/hooks/useServices';
import { formatDate } from '../../../../utils/date';
import scrollToTop from '../../../../utils/scrollToTop';

import DocumentIcon from '../../../../components/documentIcon';
import MaterialIcon from '../../../../components/materialIcon';

import { EditDocumentDialog } from './dialogs/editDocument';
import { DeleteDocumentDialog } from './dialogs/deleteDcoument';
import { EditPackageDetails } from './dialogs/editPackageDetails';
import { Templates } from './dialogs/Templates';

import styles from './styles/table.module.css';

const LABELS = {
  EMAIL: 'email',
  TOLTIP_EMAIL_FLAG: (DiadocSendDate) => `Автоматическая отправка завершена ${DiadocSendDate}`,
  ADD_DOCUMENT: 'Добавить документ',
  NOT_FOUND: 'Шаблоны не найдены',
};

const FILE_TYPES = {
  XML_970: 'xml970',
  XML: 'xml',
};

class Table extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    toAnotherTab: PropTypes.func.isRequired,
    packages: PropTypes.array.isRequired,
    packageChanges: PropTypes.array,
    packageDetails: PropTypes.array,
    selectedPackages: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    docsChange: PropTypes.bool,
    removeDocuments: PropTypes.bool,
    loadDocuments: PropTypes.func.isRequired,
  };

  static defaultProps = {
    docsChange: false,
    removeDocuments: false,
    packageChanges: [],
    packageDetails: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      editDocumentDialog: {
        show: false,
        packageId: null,
        documentId: null,
      },
      packageDetailsDialog: {
        show: false,
        packageId: null,
        documentId: null,
        comment: '',
        correction: 0,
      },
      deleteDocumentDialog: {
        show: false,
        packageId: null,
        documentId: null,
        docName: '',
        documentNumber: '',
      },
      packagesIds: [],
      flagMsgError: false,
      templatesDialog: {
        show: false,
        templates: [],
        packageId: null,
      },
      error: '',
    };
  }

  getMainCheckboxValues(selectedPackages) {
    let selectedAmt = 0;
    let unSelectedAmt = 0;
    Object.values(selectedPackages).forEach((item) => {
      if (item) selectedAmt++;
      else unSelectedAmt++;
    });

    const result = {
      value: false,
      partialSelect: false,
    };

    if (selectedAmt && !unSelectedAmt) {
      result.value = true;
    }
    if (selectedAmt && unSelectedAmt) {
      result.partialSelect = true;
    }

    return result;
  }

  toggleEditUpdate = (id) => {
    const { companyService } = this.props;

    companyService.loadPackageChanges([id]);
  };

  toggleEditDocumentDialog = (packageId, documentId) => {
    this.setState({
      editDocumentDialog: {
        show: !this.state.editDocumentDialog.show,
        packageId: packageId || null,
        documentId: documentId || null,
      },
    });
  };

  toggleDeleteDocumentDialog = (packageId, documentId, DocName, documentNumber) => {
    this.setState({
      deleteDocumentDialog: {
        show: !this.state.deleteDocumentDialog.show,
        packageId: packageId || null,
        documentId: documentId || null,
        docName: DocName || '',
        documentNumber: documentNumber || '',
      },
    });
  };

  toggleTemplatesDialog = () => {
    this.setState({ templatesDialog: { show: !this.state.templatesDialog.show, packageId: null } });
  };

  openPackageDetailsDialog = (Id, textHtml, correction, Number, packagesIds) => {
    this.setState({
      packageDetailsDialog: {
        show: true,
        packageId: Id || null,
        documentId: Number || null,
        comment: textHtml || '',
        correction: correction || null,
      },
      packagesIds,
    });
  };

  closePackageDetailsDialog = (packageId) => {
    this.props.companyService.loadPackageDetails([packageId]);

    this.setState({
      packageDetailsDialog: {
        show: false,
        packageId: null,
        documentNumber: '',
      },
    });
  };

  handleDownloadDocument = (toAnotherTab, ...opts) => this.props.companyService.downloadDocument(...opts)
    .catch((err) => {
      if (err.status === HTTPCODES.CONFLICT) {
        toAnotherTab('profile', 'showEditCompanyDialog=true');
      }
    });

  handleOpenErrorTransaction = (error) => this.setState({
    flagMsgError: true,
    showCreatePackageDialog: false,
    error,
  });

  handlePageChange = (event, page) => this.props.companyService.documents.changePage(page);

  handleSetItemsOnPage = (event, amt) => {
    event.preventDefault();
    this.props.companyService.documents.setItemsOnPage(amt);
  };

  handleCorrectionFlag = (Id, correction, textHtml) => {
    const { companyService } = this.props;

    companyService.updatePackageDetails(Id, correction, textHtml)
      .then(() => companyService.loadPackageDetails([Id]));
  };

  handleAddDocument = async (packageId) => {
    const { documents: { getTemplates } } = this.props.companyService;

    const templates = await getTemplates(packageId);

    if (!templates.length) {
      return this.setState({
        flagMsgError: true,
        error: LABELS.NOT_FOUND,
      });
    }

    this.setState({
      templatesDialog: {
        show: true,
        templates,
        packageId,
      },
      error: '',
    });
  };

  renderTemplatesDialog = () => {
    const {
      templatesDialog: {
        show,
        templates,
        packageId,
      },
    } = this.state;

    if (!show) return null;

    return (
      <Templates
        onClose={ () => this.toggleTemplatesDialog() }
        templates={ templates }
        packageId={ packageId }
      />
    );
  };

  renderDeleteDocumentDialog = () => {
    const {
      companyService, companyId, loadDocuments,
    } = this.props;
    const {
      deleteDocumentDialog: {
        show, packageId, documentId, docName, documentNumber,
      },
    } = this.state;

    return show && (
      <DeleteDocumentDialog
        companyService={ companyService }
        onClose={ () => this.toggleDeleteDocumentDialog() }
        packageId={ packageId }
        documentId={ documentId }
        docName={ docName }
        documentNumber={ documentNumber }
        companyId={ companyId }
        loadDocuments={ loadDocuments }
      />
    );
  };

  renderPackageDetailsDialog = () => {
    const { companyService } = this.props;
    const {
      show, packageId, comment, correction, documentId,
    } = this.state.packageDetailsDialog;

    return show && (
      <EditPackageDetails
        companyService={ companyService }
        onClose={ () => this.closePackageDetailsDialog(packageId) }
        packageId={ packageId }
        documentId={ documentId }
        comment={ comment }
        correction={ correction }
      />
    );
  };

  renderEmpty = () => (
    <div className={ `empty-list ${styles.empty}` }>Документов за выбранный период нет</div>
  );

  renderTableCaption = (selectedPackages) => {
    let selectedAmt = 0;
    const selectedArr = Object.values(selectedPackages);
    selectedArr.forEach((item) => {
      if (item) selectedAmt++;
    });

    return (
      <div ref={ (elem) => { this.tableCaption = elem; } } className={ styles['table-info'] }>
        Выбрано:
        { ' ' }
        { selectedAmt }
        { ' ' }
        из
        { ' ' }
        { selectedArr.length }
      </div>
    );
  };

  renderTableHeader = (selectedPackages, toggleSelectAll, { value, partialSelect }) => (
    <div className={ styles['table-header'] }>
      <div className={ `${styles['col-1-32']} ${styles.checkbox}` }>
        <Checkbox
          onChange={ toggleSelectAll }
          partialSelect={ partialSelect }
          value={ value }
        />
      </div>
      <div className={ styles['col-4-32'] }>Дата создания</div>
      <div className={ styles['col-3-32'] }>Номер</div>
      <div className={ styles['col-5-32'] }>Период</div>
      <div className={ styles['col-9-32'] }>Документы</div>
      <div className={ `${styles['col-5-32']} ${styles.padding_doc}` }>Сформированы по схеме</div>
      <div className={ `${styles['col-5-32']} ${styles.padding_download}` }>Скачать</div>
    </div>
  );

  renderShowEditIcon = (itemId, documentId) => {
    const { docsChange } = this.props;

    return docsChange && (
      <MaterialIcon
        icon='edit'
        onClick={ () => this.toggleEditDocumentDialog(itemId, documentId) }
        className={ styles.icon }
      />
    );
  };

  renderShowRemoveIcon = (itemId, documentId, DocName, documentNumber) => {
    const { docsChange, removeDocuments } = this.props;

    return docsChange && removeDocuments && (
      <MaterialIcon
        icon='delete'
        onClick={ () => this.toggleDeleteDocumentDialog(itemId, documentId, DocName, documentNumber) }
        className={ styles.icon }
      />
    );
  };

  renderManual = (packageId) => {
    const { packageChanges } = this.props;

    const changes = packageChanges && packageChanges.find(({ PackageId }) => PackageId === packageId);

    if (changes) {
      const userName = changes.UserName;
      const dateCorrection = changes.EventDate;

      const infoDocument = (userName && dateCorrection)
        ? COMMENTS.IntercomComment.INFO_DOCUMENT_CORRECTION(userName, dateCorrection)
        : COMMENTS.IntercomComment.DOCUMENT_CORRECTION;

      return (
        <div className={ `${styles['manual-changed']}` }>
          { infoDocument }
        </div>
      );
    }

    return null;
  };

  renderComment = (Id, Number, packagesIds) => {
    const { packageDetails } = this.props;

    const details = packageDetails.find(({ PackageId }) => PackageId === Id);

    let textHtml = COMMENTS.IntercomComment.NOCOMMENTTEXT;
    let correction = 0;
    let userName = '';
    let dateCorrection = '';

    if (details !== undefined) {
      textHtml = details.Comment;
      correction = details.Correction;
      userName = details.UserName;
      dateCorrection = details.EventDate;
    }

    if (textHtml === '' || textHtml === null) {
      textHtml = COMMENTS.IntercomComment.NOCOMMENTTEXT;
    }

    const infoCorrection = () => {
      if (!userName) {
        return null;
      }

      return (userName !== '' || dateCorrection !== '') && (
        <div className={ styles['info-changed'] }>
          { COMMENTS.IntercomComment.INFO_COMMENT(userName, dateCorrection) }
        </div>
      );
    };

    return (
      <div className={ styles.comment }>
        { this.renderManual(Id) }
        <div className={ styles['info-changed'] }>
          { infoCorrection() }
          { textHtml }
        </div>
        <button
          className={ styles['button-correction'] }
          onClick={ () => this.openPackageDetailsDialog(Id, textHtml, correction, Number, packagesIds) }
        >
          <i className='material-icons'>mode_edit</i>
        </button>
      </div>
    );
  };

  renderEditFlag = ({
    Id, packagesIds, DiadocSendDate,
  }) => {
    const { packageDetails } = this.props;

    const details = packageDetails.find(({ PackageId }) => PackageId === Id);

    let correction = 0;
    let textHtml = '';

    if (details !== undefined) {
      correction = details.Correction;
      textHtml = details.Comment;
    }

    const correctionButton = correction === 0
      ? (
        <button
          className={ styles['button-flag'] }
          onClick={ () => this.handleCorrectionFlag(Id, 1, textHtml, packagesIds) }
        >
          <i className='material-icons'>error_outline</i>
        </button>
      )
      : (
        <button
          className={ `${styles['button-flag']} ${styles.active}` }
          onClick={ () => this.handleCorrectionFlag(Id, 0, textHtml, packagesIds) }
        >
          <i className='material-icons'>error_outline</i>
        </button>
      );

    const renderSendingFlag = !DiadocSendDate
      ? null
      : (
        <div className={ `sw-tooltip-wrapper ${styles.email_tooltip_flag}` }>
          <i className={ `material-icons  ${styles.email_flag}` }>{ LABELS.EMAIL }</i>
          <Tooltip
            position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
          >
            { LABELS.TOLTIP_EMAIL_FLAG(formatDate(DiadocSendDate, FORMATDATE)) }
          </Tooltip>
        </div>
      );

    const correctionTooltip = correction === 0
      ? DOCUMENT_FLAG.NOT_NEED_CORRECTION
      : DOCUMENT_FLAG.NEED_CORRECTION;

    return (
      <div className={ styles['col-1-32'] }>
        <div className={ `sw-tooltip-wrapper ${styles.tooltip_flag}` }>
          { correctionButton }
          <Tooltip
            position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
          >
            { correctionTooltip }
          </Tooltip>
        </div>
        { renderSendingFlag }
      </div>
    );
  };

  renderDocuments = (Id, Documents) => {
    const { featureFlagsService: { getTripMove } } = useServices(['FeatureFlags']);

    return (
      <>
        <div className={ styles.documents }>
          { Documents.map(({
            Id: documentId,
            DocName,
            Number,
          }) => (
            <div key={ documentId } className={ styles.document }>
              { DocName }
              { this.renderShowEditIcon(Id, documentId) }
              { this.renderShowRemoveIcon(Id, documentId, DocName, Number) }
            </div>
          )) }
        </div>
        { getTripMove() && (
          <div className={ styles.button }>
            <Button
              label={ LABELS.ADD_DOCUMENT }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ () => this.handleAddDocument(Id) }
            />
          </div>
        ) }
      </>
    );
  };

  renderSchemas = (schema) => (schema || null);

  renderDocumentsIcon = (toAnotherTab, Documents, CompanyId) => {
    const icons = (documentId, FileType, DocumentPackageId, DiadocType) => {
      return FileType.map((type) => {
        const displayType = (DiadocType === 1 && type === FILE_TYPES.XML) ? FILE_TYPES.XML_970 : type;

        return (
          <DocumentIcon
            key={ type }
            type={ displayType }
            onClick={ () => this.handleDownloadDocument(
              toAnotherTab,
              CompanyId,
              DocumentPackageId,
              documentId,
              type,
            ) }
          />
        );
      });
    };

    return Documents.map(({
      Id: documentId, FileType, DocumentPackageId, DiadocType,
    }) => (
      <div key={ documentId } className={ styles['icons-row'] }>
        { icons(documentId, FileType, DocumentPackageId, DiadocType) }
      </div>
    ));
  };

  renderPackagesDocument = (
    toAnotherTab,
    visiblePackages,
    selectedPackages,
    toggleSelectedPackagesId,
    packagesIds,
  ) => visiblePackages.map(({
    Id, CreatedDate, Number, PeriodStart, PeriodEnd, Documents, CompanyId, Schema, DiadocSendDate,
  }) => (
    <div key={ Id } className={ ` ${styles['table-row']} ${styles.table_flex}` }>
      <div className={ `${styles['row-table']} ${styles.table_font_size}` }>
        <div className={ `${styles['col-1-32']} ${styles.checkbox}` }>
          <Checkbox
            value={ selectedPackages[Id] }
            onChange={ () => toggleSelectedPackagesId(Id) }
          />
          { this.renderEditFlag({
            Id, packagesIds, DiadocSendDate,
          }) }
        </div>
        <div className={ styles['col-12-32'] }>
          <div className={ styles.hedear_text } >
            <div className={ styles['col-4-12'] }>
              { formatDate(CreatedDate) }
            </div>
            <div className={ styles['col-3-12'] }>{ Number }</div>
            <div className={ styles['col-5-12'] }>{ `${formatDate(PeriodStart)} - ${formatDate(PeriodEnd)}` }</div>
          </div>
          { this.renderComment(Id, Number, packagesIds) }
        </div>
        <div className={ `${styles['col-9-32']} ${styles.documents_wrap}` }>
          { this.renderDocuments(Id, Documents) }
        </div>
        <div className={ `${styles['col-5-32']} ${styles.padding_doc}` }>
          { this.renderSchemas(Schema) }
        </div>
        <div className={ `${styles['col-5-32']} ${styles.icons}` }>
          { this.renderDocumentsIcon(toAnotherTab, Documents, CompanyId) }
        </div>
      </div>
    </div>
  ));

  renderTableBody = (toAnotherTab, visiblePackages, selectedPackages, toggleSelectedPackagesId) => {
    const packagesIds = visiblePackages.map((item) => item.Id);

    return (
      <div className={ styles['table-body'] }>
        { this.renderPackagesDocument(
          toAnotherTab,
          visiblePackages,
          selectedPackages,
          toggleSelectedPackagesId,
          packagesIds,
        ) }
      </div>
    );
  };

  renderPagination = ({
    OPT1, OPT2, OPT3,
  }, currentPage, itemsOnPage, totalItems) => (
    <div className={ styles.pagination }>
      { totalItems < OPT1
        ? null
        : (
          <div className={ styles['to-top'] } onClick={ () => scrollToTop(this.tableCaption) }>
            <MaterialIcon icon='arrow_upward' />
            <span className={ styles['to-top-text'] }>&nbsp;к началу страницы</span>
          </div>
        ) }
      <Paginate
        handlePrevious={ this.handlePageChange }
        handlePage={ this.handlePageChange }
        handleNext={ this.handlePageChange }
        currentPage={ currentPage }
        itemsOnPage={ itemsOnPage }
        totalItems={ totalItems }
      />
      { totalItems < OPT1
        ? null
        : (
          <div className={ styles['amt-opts'] }>
            <div
              className={ `${styles['amt-opt']} ${itemsOnPage === OPT1 ? styles.active : ''}` }
              onClick={ (event) => this.handleSetItemsOnPage(event, OPT1) }
            >
              { OPT1 }
            </div>
            <div
              className={ `${styles['amt-opt']} ${itemsOnPage === OPT2 ? styles.active : ''}` }
              onClick={ (event) => this.handleSetItemsOnPage(event, OPT2) }
            >
              { OPT2 }
            </div>
            <div
              className={ `${styles['amt-opt']} ${itemsOnPage === OPT3 ? styles.active : ''}` }
              onClick={ (event) => this.handleSetItemsOnPage(event, OPT3) }
            >
              { OPT3 }
            </div>
          </div>
        ) }
    </div>
  );

  render() {
    const {
      pagination: {
        visiblePackages,
        currentPage,
        itemsOnPage,
      },
      companyService,
      packages,
      selectedPackages,
      toAnotherTab,
    } = this.props;
    const {
      flagMsgError,
      editDocumentDialog,
      error,
    } = this.state;
    const { DIALOG: { MIDDLE }, BUTTON: { THEME: { FLAT } } } = COMPONENTS;

    const { toggleSelectedPackagesId, toggleSelectAll } = companyService.documents;
    const mainCheckboxValues = this.getMainCheckboxValues(selectedPackages);

    const msgErrorRuls = flagMsgError
      ? (
        <div>
          <Dialog
            show={ flagMsgError }
            onClick={ () => this.setState({ flagMsgError: false }) }
            width={ MIDDLE }
          >
            <div className={ styles.dialog_error }>
              <p>{ error }</p>
              <div className={ `${styles.row} ${styles.right_error}` }>
                <Button
                  label={ TRIPBUTTONS.CLOSE }
                  theme={ FLAT }
                  onClick={ () => this.setState({ flagMsgError: false }) }
                />
              </div>
            </div>
          </Dialog>
        </div>
      )
      : null;

    const editDocumentDialogHtml = editDocumentDialog.show
      ? (
        <EditDocumentDialog
          companyService={ companyService }
          /* onClose вызывается с объектом event, но нам нужен вызов */
          /* toggleEditDocumentDialog без параметров, поэтому в таком виде */
          onClose={ () => { this.toggleEditDocumentDialog(); this.toggleEditUpdate(editDocumentDialog.packageId); } }
          packageId={ editDocumentDialog.packageId }
          documentId={ editDocumentDialog.documentId }
          onOpenErrorTransaction={ this.handleOpenErrorTransaction }
        />
      )
      : null;

    return packages.length
      ? (
        <div className={ styles.table }>
          { this.renderTableCaption(selectedPackages) }
          { this.renderTableHeader(selectedPackages, toggleSelectAll, mainCheckboxValues) }
          { this.renderTableBody(toAnotherTab, visiblePackages, selectedPackages, toggleSelectedPackagesId) }
          { this.renderPagination(ITEMSONPAGE, currentPage, itemsOnPage, packages.length) }
          { editDocumentDialogHtml }
          { this.renderDeleteDocumentDialog() }
          { this.renderPackageDetailsDialog() }
          { this.renderTemplatesDialog() }
          { msgErrorRuls }
        </div>
      )
      : this.renderEmpty();
  }
}

export default Table;
