import { useState } from 'react';
import { Dialog, RadioButton } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import { useServices } from 'v2/hooks/useServices';
import { getCurrentDatePlusYear } from 'utils/date';

import { DECISIONBUTTONS } from '../../../../../bi/constants/decisionButtons';

import { Template } from '../../../../../bi/services/company/types';
import { Response } from 'superagent';

import styles from '../styles/dialog.module.css';

const WIDTH = '500px';

interface ITemplatesProps {
  onClose: () => void;
  templates: Template[];
  packageId: number;
}

interface ITemplateState {
  TemplateDocumentId: number;
  DestinationPackageId: number;
  Number: string;
}

const Templates = ({
  onClose,
  templates,
  packageId,
}: ITemplatesProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplateState | null>(null);
  const [waitingResponse, setWaitingResponse] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const {
    companyService: {
      documents: {
        addDocument,
        loadDocuments,
      },
      get,
    },
  } = useServices(['Company']);
  const { startDate } = get();

  const handleSelectTemplate = (id: number, number: string) => {
    setSelectedTemplate({
      TemplateDocumentId: id,
      DestinationPackageId: packageId,
      Number: number,
    });
  };

  const handleSendDocument = async () => {
    setWaitingResponse(true);

    try {
      await addDocument(selectedTemplate);
      await loadDocuments(startDate, getCurrentDatePlusYear());
    } catch (e) {
      if (e instanceof Response) {
        setError(e.body);
        setWaitingResponse(false);

        return;
      }
    }

    setWaitingResponse(false);
    onClose();
  };

  const renderTemplates = () => {
    return (
      <>
        { templates.map(({
          Id,
          DocumentName,
          Number,
        }) => (
          <RadioButton
            key={ Id }
            onChange={ () => handleSelectTemplate(Id, Number) }
            checked={ selectedTemplate?.TemplateDocumentId === Id }
          >
            { DocumentName }
          </RadioButton>
        )) }
      </>
    );
  };

  const renderError = () => {
    if (!error) return null;

    return (
      <span className='error-msg'>
        { error }
      </span>
    );
  };

  return (
    <Dialog onClick={ onClose } width={ WIDTH } showCloseButton>
      <div className={ styles.dialog }>
        <div className={ styles.content }>
          { renderTemplates() }
        </div>
        <div className={ styles.actions }>
          <DecisionButtons
            waitingResponse={ waitingResponse }
            onSave={ () => handleSendDocument() }
            onCancel={ onClose }
            labelSave={ DECISIONBUTTONS.LABELS.ADD_DOCUMENT }
            disabled={ !selectedTemplate }
          />
        </div>
        { renderError() }
      </div>
    </Dialog>
  );
};

export { Templates };
